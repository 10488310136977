.assignment-detail {
  display: flex;
  gap: 8px;
  padding: 40px 20px;

  .detail-container {
    flex: 1 1 70%;
  }

  .assigned-detail {
    background-color: #ffffff;
    border: 1px solid #f2f4f7;
    padding: 20px 16px;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .divider {
    height: 100%;
  }
}

.container {
  flex: 1 1 80%;

  :global(.ant-collapse-item-active) {
    :global(.ant-collapse-header) {
      border-radius: 8px 8px 0 0 !important;
    }
  }

  :global(.ant-collapse-item) {
    margin-bottom: 10px;
    border: 1px solid #f2f4f7;

    :global(.ant-collapse-header) {
      border: 1px solid #ededed;
      border-radius: 8px !important;
      background: #13a2a2;
      color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      padding: 16px 20px;
    }

    :global(.ant-collapse-content) {
      border: 1px solid #f2f4f7;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}

.tag {
  border: 1px solid #e2e2e2;
  background-color: transparent;
  border-radius: 36px;
  padding: 5px 12px;
}

// .container {
//   padding: 20px;
//   :global(.ant-tag) {
//     padding: 5px 12px;
//     margin: 0;
//     border-radius: 36px;

//     span {
//       font-style: normal;
//       font-weight: 600;
//       font-size: 12px;
//       line-height: 18px;
//     }

//     &:global(.fill) {
//       border: none;
//     }
//   }
// }

.information-block {
  background-color: #ffffff;
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  padding-bottom: 16px;

  &__header-container {
    width: 100%;
    background: #13a1a1;
    padding: 16px 20px;

    h4 {
      color: #ffffff !important;
    }
  }

  &:not(:first-child) {
    margin-top: 10px;
  }

  .updatePrefCol {
    display: flex;
    gap: 8px;
  }

  .row {
    padding: 18px 20px;
    justify-content: space-between;
    align-items: center;
    gap: 20%;

    &:not(:first-child) {
      border-top: 1px solid #ededed;
    }

    &:last-child {
      padding-bottom: 0;
    }

    span {
      word-break: keep-all;
    }

    :global(.ant-btn-link) {
      color: #03abab;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 0;
    }

    :global(.ant-collapse) {
      background-color: transparent;
      border: none;
      box-shadow: none;
      width: 100%;

      > :global(.ant-collapse-item) {
        border: none;

        > :global(.ant-collapse-header) {
          padding: 0;
        }

        > :global(.ant-collapse-content) {
          border: none;
          background: #f9f9f9;
          border-radius: 8px;
          padding: 20px;

          > :global(.ant-collapse-content-box) {
            padding: 0;

            > :global(.ant-row) {
              > :global(.ant-col) {
                > :global(.ant-row) {
                  padding-top: 6px;
                  padding-bottom: 6px;
                  height: 44px;

                  &:first-child {
                    margin-bottom: 12px;
                    padding-top: 0;
                    padding-bottom: 12px;
                    border-bottom: 1px solid #ededed;
                  }
                }
              }
            }
          }
        }
      }
    }

    :global(.ant-radio-group) {
      white-space: nowrap;
    }

    :global(.ant-select) {
      min-width: 140px;
      width: fit-content;
      margin-left: 8px;
    }
  }
}

.setting-block {
  background: #ffffff;
  /* Gray/100 */

  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  padding: 12px 10px;

  &:not(:first-child) {
    margin-top: 20px;
  }

  h5 {
    margin-bottom: 4px;
  }

  strong {
    margin-bottom: 4px;
    margin-top: 12px;
  }

  .row {
    width: 100%;
    padding: 12px 0;
    justify-content: space-between;
    gap: 16px;
    align-items: center;

    &:not(:first-child) {
      border-top: 1px solid #ededed;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .rowData {
    width: 100%;
    padding: 12px 0;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    display: inline-grid;

    &:not(:first-child) {
      border-top: 1px solid #ededed;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .checking-block {
    background: #ffffff;
    border-bottom: 3px solid #ededed;
    // border: 2px solid #03abab;
    border-radius: 0px;
    width: 100%;
    padding: 20px 10px;

    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  .row_fix {
    width: 100% !important;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    margin-top: 8px !important;
  }
}

.statusWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 100%;
  height: 28px;

  /* Grey/White */

  background: #ffffff;
  /* Grey/Grey 300 */

  border: 1px solid #bcbcbc;
  border-radius: 36px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  /* identical to box height, or 200% */
}

.badgeWrapper {
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  /* identical to box height, or 164% */

  /* GreenYellow/GreenYellow 500 */

  color: #98a933;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;
  gap: 10px;

  width: 208px;
  height: 28px;

  /* GreenYellow/GreenYellow 100 */

  background: #edf3c8;
  border-radius: 36px;
}

.submitClaimNumber {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 8px;

  width: 250px;
  height: 48px;

  /* Primary/Primary 200 */

  background: #03abab;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Grey/White */

  color: #ffffff;
}

.inputClaimNumber {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 10px;

  width: 250px;
  height: 48px;

  /* Grey/White */

  background: #ffffff;
  /* Grey/Grey 200 */

  border: 1px solid #e2e2e2;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
}

.filed {
  color: #03abab;
}

.approved {
  color: #31b43e;
}

.rejected {
  color: #f04438;
}

.modal-container {
  :global(.ant-modal-content) {
    padding: 30px;
  }
}

.form-container {
  z-index: 1;

  > &__item {
    margin-bottom: 0;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }

    > h2 {
      margin: 0;
    }

    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #111111;
    }

    :global(.ant-input) {
      height: 48px;
      padding-left: 16px;
      padding-right: 16px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;
    }

    :global(.ant-input-password) {
      padding: 0;
      padding-right: 16px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;

      :global(.ant-input) {
        height: 48px;
        box-shadow: none;
        border: none;
        padding: 0;
        padding-left: 16px;
        border-radius: 5px;
      }
    }

    button {
      width: 100%;
      height: 48px;
      // background: #03abab;
      border-radius: 5px;

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      color: #ffffff;
    }
  }
}

:global(.file-action) {
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  & > *,
  > a {
    color: #03abab;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  img {
    margin-right: 8px;
  }
}

.reject_reasons_modal {
  :global(.ant-modal-content) {
    padding: 32px 0;

    :global(.ant-modal-title) {
      margin: 0 32px;

      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;

      color: #111111;
    }

    :global(.ant-modal-body) {
      :global(.ant-form) {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 32px 0;

        > :global(.ant-form-item) {
          margin: 0;

          :global(.ant-checkbox-group) {
            margin: 0 32px;
            display: flex;
            flex-direction: column;
            gap: 16px 0;

            > :global(.ant-checkbox-wrapper.ant-checkbox-group-item) {
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
              align-items: center;
              margin-inline-start: 0;

              :global(.ant-checkbox-inner) {
                border-radius: 5px;

                height: 20px;
                width: 20px;

                &::after {
                  inset-inline-start: 28%;
                }
              }

              :global(.ant-checkbox-checked) {
                :global(.ant-checkbox-inner) {
                  background-color: #e2f2f2;
                  border-color: #03abab;

                  &::after {
                    border-color: #03abab;
                  }
                }

                &::after {
                  display: none;
                }
              }

              > span {
                padding: 0;
              }

              &::after {
                display: none;
              }
            }
          }
        }

        > :global(.ant-btn-primary) {
          margin: 0 32px;
          height: 48px;
          background: #f04438;
          border-radius: 5px;
          padding: 10px 30px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          color: #ffffff;
          box-shadow: none;

          &:hover {
            background: #ef6d64;
          }
        }

        > hr {
          margin: 0;
          border: 1px solid #ededed;
        }
      }
    }
  }
}
