@import '../../../style/varible';

.table {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

@media (max-width: $screen-md-max) {

  .pagination {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
}