.block-item {
    background-color: #e2f2f2;
    padding: 25px;
    height: 110px;
    .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    .description {
        font-size: 24px;
        font-weight: 600;
        color: #13A1A1;
    }
}
.stats-page {
    padding: 30px;
}