@import "../../style/varible";

.slider {
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
  background: $mercury !important;

  .logo {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu {
    background: $mercury !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #666666;
    padding-left: 16px;
    padding-right: 16px;
    border-inline-end: unset !important;

    :global(.ant-menu-item-selected) {
      background-color: $primary-color;
      color: white;

      path {
        stroke: white;
      }
    }
  }
}

.header {
  background: #037c7c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding-inline: 20px;

  > h4 {
    margin: 0;
    color: #fff;
  }

  strong {
    color: #fff;
  }

  :global(.ant-typography.ant-typography-secondary) {
    color: #fff;
  }
}

.footer {
  background: $mercury1 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  // mar
}

.content {
  background: $mercury1 !important;
  overflow: initial;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 140px);
}
