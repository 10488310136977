.container {
  display: flex;
  flex-wrap: wrap;

  .block {
    flex: 1 1 33%;
    display: flex;
    flex-direction: column;
    margin: 15px 0;

    &__title {
      color: #666666;
      margin-bottom: 8px;
    }

    &__desc {
      color: #111111;
      font-weight: 600;
    }

    &_md {
      flex: 1 1 66%;
      display: flex;
      flex-direction: column;
      margin: 15px 0;
    }

    &_lg {
      flex: 1 1 100%;
      display: flex;
      flex-direction: row;
      margin: 15px 0;
      .block__title {
        min-width: 33%;
      }

      .block__desc {
        width: 100%;
      }
    }
  }

  .action-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  :global(.ant-select) {
    width: 200px
  }
}