@import '../../style/varible';

.pageTitle {
  font-size: 24px !important;
  line-height: 32px !important;
  margin-bottom: 0 !important;
}

@media (min-width: $screen-lg-min) {
  .pageTitle {
    font-size: 30px !important;
    line-height: 38px !important;
  }
}