.container {
  :global(.ant-tag) {
    padding: 5px 12px;
    margin: 0;
    border-radius: 36px;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }

    &:global(.fill) {
      border: none;
    }

    &:global(.outline) {
      border: 1px solid #e2e2e2;
      background-color: transparent;
    }
  }

  .claim-stats {
    .claim-stats__item {
      width: 100%;
      background-color: #03abab;
      min-height: 68px;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 5px;
      padding: 0 20px;
      gap: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-color: #03abab;
      text-align: left;

      span {
        color: #fff;
      }

      h3 {
        color: #fff;
        margin: 0;
      }

      &.active {
        background-color: #fff;

        span {
          color: #111111;
        }

        h3 {
          color: #03abab;
        }
      }
    }
  }
}

.table-container {
  :global(.ant-table) {
    background-color: transparent;
    overflow: auto;

    :global(.ant-table-content) {
      > table {
        border-spacing: 0 12px;
        border-radius: 0;

        > thead {
          > tr {
            th {
              background-color: #e2f2f2 !important;
              font-style: normal !important;
              font-weight: 600 !important;
              font-size: 14px !important;
              line-height: 20px !important;

              color: #037c7c !important;
              border-start-end-radius: 0 !important;
              border-start-start-radius: 0 !important;
            }
          }
        }

        > tbody {
          > tr {
            cursor: pointer;
            box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08),
              0px 4px 6px -2px rgba(16, 24, 40, 0.03);
            min-height: 60px;
            overflow: hidden;
            border-radius: 8px;

            & {
              > td:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
              }
              > td:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
              }
            }

            > td {
              background-color: #ffffff;

              :global(.ant-tag) {
                padding: 5px 12px;
                margin: 0;
                border-radius: 36px;

                span {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 18px;
                }

                &:global(.fill) {
                  border: none;
                }

                &:global(.outline) {
                  border: 1px solid #e2e2e2;
                  background-color: transparent;
                }
              }
            }

            &:global(.ant-table-expanded-row) {
              > td {
                background-color: #fbfbfb;
              }
              table {
                width: fit-content;
                min-width: 80%;
                border-spacing: 10px;
                thead {
                  > tr > th {
                    text-align: left;
                    border-bottom: 1px solid #ededed;
                    padding-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-container {
  :global(.ant-modal-content) {
    padding: 30px;
  }
}

.form-container {
  z-index: 1;

  > &__item {
    margin-bottom: 0;
    margin-top: 30px;

    > h2 {
      margin: 0;
    }

    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #111111;
    }

    :global(.ant-input) {
      height: 48px;
      padding-left: 16px;
      padding-right: 16px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;
      width: 100%;
    }

    :global(.ant-select-selector) {
      height: 48px !important;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px !important;

      :global(.ant-select-selection-search) {
        input {
          height: 100% !important;
        }
      }

      :global(.ant-select-selection-placeholder) {
        line-height: 48px !important;
      }
    }
    button {
      width: 100%;
      height: 48px;
      // background: #03abab;
      border-radius: 5px;

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      color: #ffffff;
    }
  }
}
