.accountStatement {
  padding: 30px 20px;
  border: 1px solid #ededed;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 22px;

  .row {
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    display: flex;

    .leftCol {
      flex: 1 80%;
    }

    .rightCol {
      flex: 1 20%;
    }
  }

  .select {
    min-width: 100%;
  }
}