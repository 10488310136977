.container {
  padding: 20px;

  .form-container {
    padding: 50px;
    background: #ffffff;

    border: 1px solid #f2f4f7;

    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;

    :global(.ant-input),
    :global(.ant-input-number) {
      height: 48px;
      padding-left: 16px;
      padding-right: 16px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;
      width: 100%;

      :global(.ant-input-number-input-wrap) {
        height: 100%;

        :global(.ant-input-number-input) {
          height: 100%;
          padding: 0;
        }
      }
    }

    :global(.ant-input-password) {
      padding: 0;
      padding-right: 16px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;

      :global(.ant-input) {
        height: 48px;
        box-shadow: none;
        border: none;
        padding: 0;
        padding-left: 16px;
        border-radius: 5px;
      }
    }
    button {
      width: 100%;
      height: 48px;
      // background: #03abab;
      border-radius: 5px;

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      color: #ffffff;
    }
  }
}
