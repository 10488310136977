.main {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.btnLogin {
  width: 250px;
  height: 52px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
}

.form-container {
  width: min(360px, calc(100% - 32px));

  padding: 30px 30px 42px 30px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  border: 1px solid #f2f4f7;
  background-color: #fff;
  z-index: 1;

  > &__item {
    margin-bottom: 0;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }

    > h2 {
      margin: 0;
    }

    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #111111;
    }

    :global(.ant-input) {
      height: 48px;
      border: 1px solid #e2e2e2;
      padding-left: 16px;
      padding-right: 16px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;
    }

    :global(.ant-input-password) {
      height: 48px;
      border: 1px solid #e2e2e2;
      padding-left: 16px;
      padding-right: 16px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;

      :global(.ant-input) {
        height: 100%;
        box-shadow: none;
      }
    }
    button {
      width: 100%;
      height: 48px;
      // background: #03abab;
      border-radius: 5px;

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      color: #ffffff;
    }
  }
}
