.container {
  padding: 20px;
}

.table-container {
  :global(.ant-table) {
    background-color: transparent;
    overflow: auto;
  }
  table {
    border-spacing: 0 12px;

    > thead {
      > tr {
        th {
          background-color: #e2f2f2 !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 20px !important;

          color: #037c7c !important;
        }
      }
    }

    > tbody {
      > tr {
        cursor: pointer;
        border: 1px solid #ededed;
        box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08),
          0px 4px 6px -2px rgba(16, 24, 40, 0.03);
        border-radius: 8px;
        min-height: 60px;
        overflow: hidden;

        & {
          > td:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          > td:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }

        > td {
          background-color: #ffffff;

          :global(.ant-tag) {
            padding: 5px 12px;
            margin: 0;
            border-radius: 36px;

            span {
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
            }

            &:global(.fill) {
              border: none;
            }

            &:global(.outline) {
              border: 1px solid #e2e2e2;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.modal-container {
  :global(.ant-modal-content) {
    padding: 30px;
  }
}

.form-container {
  z-index: 1;

  > &__item {
    margin-bottom: 0;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }

    > h2 {
      margin: 0;
    }

    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #111111;
    }

    :global(.ant-input),
    :global(.ant-input-number) {
      height: 48px;
      padding-left: 16px;
      padding-right: 16px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;
      width: 100%;

      :global(.ant-input-number-input-wrap) {
        height: 100%;

        :global(.ant-input-number-input) {
          height: 100%;
          padding: 0;
        }
      }
    }

    :global(.ant-input-password) {
      padding: 0;
      padding-right: 16px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;

      :global(.ant-input) {
        height: 48px;
        box-shadow: none;
        border: none;
        padding: 0;
        padding-left: 16px;
        border-radius: 5px;
      }
    }
    button {
      width: 100%;
      height: 48px;
      // background: #03abab;
      border-radius: 5px;

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      color: #ffffff;
    }
  }
}
