.container {
  .block {
    margin: 15px 0;
    &__title {
      & > * {
        font-weight: 600;
        font-size: 18px;
        color: #111111;
      }
      margin-bottom: 16px;
    }
  }
  :global(.ant-tabs) {
    :global(.ant-tabs-nav) {
      margin: 0;

      & * {
        font-weight: 600;
        font-size: 13px;
        color: #666666;
      }

      :global(.ant-tabs-ink-bar) {
        display: none;
      }

      :global(.ant-tabs-tab-active) {
        background: #03abab;
        & > * {
          color: #ffffff !important;
        }
      }

      :global(.ant-tabs-nav-list) {
        width: 100%;
        display: flex;
        & > :global(.ant-tabs-tab) {
          margin: 0;
          &:first-child {
            border-top-left-radius: 8px;
          }

          &:last-of-type {
            border-top-right-radius: 8px;
          }
        }

        & > * {
          width: 25%;

          & > * {
            width: 100%;
            text-align: center;
          }
        }

        &::after {
          content: none;
        }
      }
    }

    :global(.ant-tabs-content) {
      border: 1px solid #03abab;
      border-radius: 0px 0px 8px 8px;
      padding: 30px 20px;
    }
  }

  .table-row {
    padding: 22px 0;
    min-height: 80px;
    border-top: 1px solid #ededed;
  }

  .select {
    width: 100%;
    :global(.ant-select-selector) {
      background: #f5f5f5;
      border: 1px solid #ededed;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .file-action-container {
    display: flex;
    gap: 22px;
    padding-top: 22px;
  }
}

.remarks {
  width: 100%;
  .inputWrapper {
    width: 100%;
    display: flex;
    gap: 8px;
    margin-top: 12px;

    .updateInput {
      height: 48px;
      width: 80%;
      flex: 1;
    }

    .updateButton {
      height: 48px;
      padding: 0 35px;
      background: #03abab;
    }
  }
}

.table_balance {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden;
  border: 1px solid #03abab;
  border-radius: 0px 0px 8px 8px;
  > div {
    padding: 24px 16px;
    border: 1px solid #ededed !important;

    > div {
      display: flex;
      align-items: center;

      > p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        color: #111111;
        margin: 0;
      }
      margin-bottom: 8px;
    }

    > p {
      &:nth-child(2) {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;

        color: #111111;
        margin: 0;
      }
    }
  }

  :global {
    .font-semibold {
      font-weight: 600;
    }
    .mr-2 {
      margin-right: 4px;
    }
  }

  > p {
    grid-column: 1/5;
    margin: 30px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #111111;

    text-align: center;
  }
}
