@import '../../../style/varible';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 23px;
}

.search {
  max-width: 350px;
  width: 100%;
}

.filter {
  display: flex;
  gap: 24px;
  align-items: center;

  .filterItem {
    display: flex;
    gap: 8px;
    align-items: center;

    :global(.ant-select) {
      width: 150px;
    }
  }

  .filterItemPc {
    display: flex;
  }

  .filterItemMb {
    display: none;
  }
}

:global {

  .ant-select-selector,
  .ant-input-affix-wrapper {
    border-radius: 5px !important;
  }
}

@media (max-width: $screen-md-max) {
  .wrapper {
    gap: 8px;
  }

  .search {
    max-width: unset;
  }

  .filter {
    gap: 4px;

    .filterItemPc {
      display: none;
    }

    .filterItemMb {
      display: flex;
    }
  }
}
