.container {
  display: flex;
  flex-wrap: wrap;

  .information-block {
    flex: 1 1 33%;
    gap: 16px;
    font-size: 14px;
    line-height: 20px;
    margin: 15px 0;

    .information-block__label {
      font-weight: 500;
      color: #666666;
    }

    .information-block__description {
      font-weight: 600;
      color: #111111;
    }
  }

  .amount-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .information-block {
      flex: 1 1 33%;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .information-block__description {
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;
          color: #13A1A1;
        }
    }
  }
}