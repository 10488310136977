.container {
  padding: 20px;
}

.table-container {
  :global(.ant-table) {
    background-color: transparent;
    overflow: auto;

    :global(.ant-table-content) {
      > table {
        border-spacing: 0 12px;
        border-radius: 0;

        > thead {
          > tr {
            th {
              background-color: #e2f2f2 !important;
              font-style: normal !important;
              font-weight: 600 !important;
              font-size: 14px !important;
              line-height: 20px !important;

              color: #037c7c !important;
              border-start-end-radius: 0 !important;
              border-start-start-radius: 0 !important;
            }
          }
        }

        > tbody {
          > tr {
            cursor: pointer;
            box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08),
              0px 4px 6px -2px rgba(16, 24, 40, 0.03);
            min-height: 60px;
            overflow: hidden;
            border-radius: 8px;

            & {
              > td:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
              }
              > td:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
              }
            }

            > td {
              background-color: #ffffff;

              :global(.ant-tag) {
                padding: 5px 12px;
                margin: 0;
                border-radius: 36px;

                span {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 18px;
                }

                &:global(.fill) {
                  border: none;
                }

                &:global(.outline) {
                  border: 1px solid #e2e2e2;
                  background-color: transparent;
                }
              }
            }

            &:global(.ant-table-expanded-row) {
              > td {
                background-color: #fbfbfb;
              }
              table {
                width: 100%;
                border-spacing: 10px;
                thead {
                  > tr > th {
                    text-align: left;
                    border-bottom: 1px solid #ededed;
                    padding-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
