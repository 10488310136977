.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border: 1px solid #EDEDED;
  border-radius: 0px 8px 8px 8px;
}

.list {
  display: flex;
  justify-content: space-between;

  .divider {
    width: 100%;
    margin: 20px 0;
  }

  .information {
    display: flex;
    flex-direction: column;
    align-items: start;
    max-width: 80%;

    .date {
      font-size: 12px;
      line-height: 20px;
      color: #666666;
    }

    .description {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #111111;
    }
  }
}