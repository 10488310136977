.footer {
  // height: 56px;
  // margin-bottom: 40px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;

  :global(.ant-typography-secondary) {
    color: #666666;
  }
}

.row {
  display: flex;
  gap: 6px;
  align-items: center;

  :global(.ant-typography) {
    font-size: 16px;
  }
}