@import '../../style/varible';

.main {
  margin-left: auto;
  margin-right: auto;
  z-index: 12;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #111111;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.content {
  width: 100%;
  max-width: 770px;
  background: #FFFFFF;
  border: 1px solid #F2F4F7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  padding: 50px;
}

.contentTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  color: #111111;
}

.contentSubTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #666666;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.contentInfo {
  display: flex;
  flex-direction: column;
  background: #F1F7F7;
  border: 1px solid #E2F2F2;
  border-radius: 8px;
  color: #037C7C;
  padding: 20px;
  margin-top: 24px;

  ul {
    margin: 0;
    padding: 0;
    padding-left: 10px;
    display: flex;
    gap: 12px;
    flex-direction: column;

    li {
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #111111;
        margin-left: 5px;
      }
    }
  }
}

.formItem {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.formLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #111111;
}

.formLabelRequired {
  margin-left: 5px;
  color: red;
}

.formSignature {
  border: 2px dashed #BCBCBC;
  border-radius: 8px;
  width: 100%;
  height: 180px;
}

.formValue {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
}

.formInput {
  width: 100%;
  height: 48px;
  padding: 12px 16px;

  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  font-size: 18px;
}

.formSubTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #666666;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.buttons {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}


.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 8px;
  height: 48px;
  background: #03ABAB;
  border-radius: 5px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  border: none;

  cursor: pointer;
  &:hover {
    // color:rgb(240, 240, 240);
    color: #FFFFFF !important;
    background: #029797;
  }
  &:disabled {
    // color:rgb(240, 240, 240);
    color: #FFFFFF;
    background: #029797;
    opacity: 0.5;
  }
}
.back {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 8px;
  max-width: 162px;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  border-radius: 5px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #111111;

  cursor: pointer;
  &:hover {
    // color:rgb(240, 240, 240);
    color: #111111 !important;
    background: #FFFFFF;
  }
  &:disabled {
    // color:rgb(240, 240, 240);
    color: #FFFFFF;
    background: #029797;
    opacity: 0.5;
  }
}

.result {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  min-height: 500px;
  padding: 8px;
}

.resultText {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #111111;
  max-width: 660px;
  width: 100%;
}

// @media (min-width: $screen-lg-min) {
//   .title {
//     font-size: 72px;
//     line-height: 90px;

//     margin-top: 93px;
//   }

//   .subTitle {
//     font-size: 20px;
//     line-height: 30px;
//   }

//   .touch {
//     font-size: 20px;
//     line-height: 30px;
//   }

//   .inputWrapper {
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;

//     input {
//       max-width: 300px !important;
//       padding-top: 12 !important;
//       padding-bottom: 12 !important;
//     }
//   }
// }
