@import '../../../style/varible';

.header {
  height: 70px;
  width: 100%;
}

.headerWrappper {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

%menuActive {
  color: $primary-2;
  position: relative;

  &::after {
    position: absolute;
    bottom: -2px;
    content: '';
    height: 2px;
    background: $primary-2;
    width: 100%;
    left: 0;
  }
}

.menu {
  height: 100%;

  >ul {
    display: flex;
    align-items: center;
    gap: 40px;
    height: 100%;
    list-style: none;

    >li {
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;


      >a {
        color: $text-color;

        &:hover {
          @extend %menuActive;
        }
      }

      .active {
        @extend %menuActive;
      }
    }
  }
}

@media (max-width: $screen-md-max) {
  .menu {
    display: none;
  }
}