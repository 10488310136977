.content-container {
  display: flex;
  flex-direction: column;
  //   background: #f1f7f7;
  //   border: 1px solid #e2f2f2;
  //   border-radius: 8px;
  //   color: #037c7c;
  margin-top: 24px;
  font-family: "Times New Roman", Times, serif !important;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: white;
  color: #000;
  p {
    margin-bottom: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    padding-left: 10px;
    display: flex;
    gap: 12px;
    flex-direction: column;

    li {
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #111111;
        margin-left: 5px;
      }
    }
  }

  table {
    > tr > td:not(:first-child) {
    }
    >tr {
      width: 150px;
    }
  }

  .claim-rules {
    text-align: justify;
  }

  .signature-block {
    display: flex;
    > div {
      flex: 1;
    }
  }

  .center {
    text-align: center;
  }

  .nowrap {
    white-space: nowrap;
  }

  .debtors-block {
    display: flex;
    gap: 32px;
    > div {
      &:first-child {
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        flex: 2;
      }
      &:last-child {
        flex: 1;
      }
    }
  }

  .transactions-block {
    th,
    td {
      border: 1px solid #000;
    }
  }

  .break-before {
    page-break-before: always;
  }
}
