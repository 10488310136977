@import "../../../style/varible";

.container {
  padding: 20px;

  .table {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;
    overflow-x: auto;
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  @media (max-width: $screen-md-max) {
    .pagination {
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }
  }

  .table-container {
    :global(.ant-table) {
      background-color: transparent;
      overflow: auto;
    }
    table {
      border-spacing: 0 12px;

      > thead {
        > tr {
          th {
            background-color: #e2f2f2 !important;
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 14px !important;
            line-height: 20px !important;

            color: #037c7c !important;
          }
        }
      }

      > tbody {
        > tr {
          cursor: pointer;
          border: 1px solid #ededed;
          box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08),
            0px 4px 6px -2px rgba(16, 24, 40, 0.03);
          border-radius: 8px;
          min-height: 60px;
          overflow: hidden;

          & {
            > td:first-child {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }
            > td:last-child {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }

          > td {
            background-color: #ffffff;

            :global(.ant-tag) {
              padding: 5px 12px;
              margin: 0;
              border-radius: 36px;

              span {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
              }

              &:global(.fill) {
                border: none;
              }

              &:global(.outline) {
                border: 1px solid #e2e2e2;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}
