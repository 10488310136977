.container {
  .block {
    margin: 15px 0;
    &__title {
      & > * {
        font-weight: 600;
        font-size: 18px;
        color: #111111;
      }
      margin-bottom: 16px;
    }
  }
  :global(.ant-tabs) {
    :global(.ant-tabs-nav) {
      margin: 0;

      & * {
        font-weight: 600;
        font-size: 13px;
        color: #666666;
      }

      :global(.ant-tabs-ink-bar) {
        display:  none;
      }

      :global(.ant-tabs-tab-active) {
        background: #03ABAB;
        & > * {
          color: #FFFFFF !important;
        }
      }

      :global(.ant-tabs-nav-list) {
        width: 100%;
        display: flex;
        & > :global(.ant-tabs-tab) {
          margin: 0;
          &:first-child {
            border-top-left-radius: 8px;
          }

          &:last-of-type {
            border-top-right-radius: 8px;
          }
        }

        & > * {
          width: 25%;

          & > * {
            width: 100%;
            text-align: center;
          }
        }

        &::after {
          content: none;
        }
      }
    }

    :global(.ant-tabs-content) {
      border: 1px solid #03ABAB;
      border-radius: 0px 0px 8px 8px;
      padding: 30px 20px;
    }
  }

  .table-row {
    padding: 22px 0;
    min-height: 80px;
    border-top: 1px solid #EDEDED;
  }

  .select {
    width: 100%;
    :global(.ant-select-selector) {
      background: #F5F5F5;
      border: 1px solid #EDEDED;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .file-action-container {
    display: flex;
    gap: 22px;
    padding-top: 22px;
  }
}

.remarks {
  width: 100%;
  .inputWrapper {
      width: 100%;
      display: flex;
      gap: 8px;
      margin-top: 12px;

      .updateInput {
        height: 48px;
        width: 80%;
        flex: 1;
      }

      .updateButton {
        height: 48px;
        padding: 0 35px;
        background: #03ABAB;
      }
    }
}