@import '../../style/varible';

.main {
  margin-left: auto;
  margin-right: auto;
  z-index: 12;
  position: relative;
}

.content {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.pageHeader {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  .title {
    margin: 0;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: flex;
    text-align: center;
  }
}

$color-waiting: #E2E2E2;

.steps {
  margin-top: 30px;

  :global {
    .ant-steps-icon {
      font-size: 0px;
    }

    // .ant-steps-item-icon {
    //   width: 24px !important;
    //   height: 24px !important;
    //   display: flex !important;
    //   align-items: center;
    //   justify-content: center;
    //   z-index: 2;
    //   background-color: $color-waiting !important;
    //   position: relative !important;
    // }

    .ant-steps-item-icon {
      width: 40px !important;
      height: 40px !important;
      top: -6px;
      position: relative;
      background-color: unset !important;
      border: 2px solid $primary-color;
      position: relative !important;
      z-index: 2;

      &::after {
        width: 24px;
        height: 24px;
        left: 6px;
        top: 6px;
        content: "";
        position: absolute;
        background-color: $color-waiting;
        border-radius: 50%;
      }
    }

    .ant-steps-item-title {
      font-weight: 600;
      font-size: 16px;
      color: $color-waiting !important;
    }

    .ant-steps-item-tail {
      top: 7px !important;
      padding: 4px 0px !important;
      z-index: 1;

      &::after {
        height: 4px;
        background-color: $color-waiting !important;
      }
    }

    .ant-steps-item-process {
      .ant-steps-item-title {
        color: $primary-2 !important;
      }

      .ant-steps-item-icon {
        width: 40px !important;
        height: 40px !important;
        top: -6px;
        position: relative;
        background-color: unset !important;
        background-color: white !important;
        border: 2px solid $primary-2;

        &::after {
          width: 18px;
          height: 18px;
          left: 9px;
          top: 9px;
          content: "";
          position: absolute;
          background-color: $primary-2;
          border-radius: 50%;
        }
      }
    }

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        width: 40px !important;
        height: 40px !important;
        top: -6px;
        position: relative;
        background-color: unset !important;
        border: none;
        position: relative !important;
        z-index: 2;

        &::after {
          width: 24px;
          height: 24px;
          left: 6px;
          top: 6px;
          content: "";
          position: absolute;
          background-color: $primary-2;
          border-radius: 50%;
        }
      }

      .ant-steps-item-tail {
        &::after {
          height: 4px;
          background-color: $primary-2 !important;
        }
      }

      .ant-steps-item-title {
        color: $primary-2 !important;
      }
    }
  }
}

.card {
  margin-top: 40px;
}

:global {
  .sd-root-modern {
    background-color: white;
  }

  .sd-element {
    padding: 0 !important;
    box-shadow: none !important;
  }

  .sd-input {
    background-color: white !important;
    border: 1px solid $color-waiting !important;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%) !important;
    border-radius: 5px !important;

    &:focus {
      border: 1px solid $primary-2 !important;
    }
  }

  .sd-element--complex:not(.sd-element--collapsed)>.sd-element__header--location-top {
    .sd-element__title .sv-string-viewer {
      font-size: 16px;
      font-weight: 600;
    }

  }

  div[data-key^="totalClaim"],
  div[data-key^="totalWithdrawn"],
  div[data-key^="totalDeposit"] {
    margin-top: 14px;
    padding-bottom: 30px;
    border-bottom: 1px solid #EDEDED;

    .sd-element__title .sv-string-viewer {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .sd-element__title {
    .sv-string-viewer {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .sd-page__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .sv-components-column {
    margin: 0px !important;
    max-width: unset !important;
  }

  .sd-body__page {
    background: white !important;
    padding: 50px !important;
    border: 1px solid #F2F4F7 !important;
    box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%), 0px 4px 6px -2px rgb(16 24 40 / 3%) !important;
    border-radius: 8px !important;
  }

  .sd-checkbox__decorator {
    background: white;
  }

  .sd-question__erbox--outside-question {
    margin: 0 !important;
    width: 100% !important;
  }

  .sd-container-modern {
    margin: 0 !important;
  }

  .sd-file {
    height: 150px !important;
    min-height: unset !important;
    margin-top: 10px;

    .sd-file__decorator {
      border-radius: 5px;
      // margin-top: 10px;
    }

    .sd-file__image-wrapper {
      img {
        background-color: unset;
      }
    }

    .sd-file__drag-area-placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #111111;
    }
  }

  .sd-expression {
    font-weight: 600 !important;
    font-size: 24px !important;
  }

  div[data-key="price-2"] {
    margin-top: 12px !important;
    margin-bottom: 12px !important;

    .sd-expression {
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }

  div[data-key^="celsius_transactions"] {
    margin-top: 30px;
  }

  .sd-paneldynamic__panel-wrapper:not(:first-child) {
    margin-top: 16px !important;
  }

  div[data-key^="celsius_address"] {
    position: relative;
  }

  .sd-root-modern {
    background-color: transparent;
  }

  .sd-action-bar {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 !important;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .sd-navigation__next-btn {
    background: $primary-2;
    color: white;
  }

  .sd-paneldynamic__btn {
    outline: none;
    background-color: var(--primary-light, rgba(25, 179, 148, 0.1));
    cursor: pointer;
    opacity: 1;
  }

  div[data-key^="celsius_balances"] {
    >div {
      border-top: 1px solid #EDEDED;
      margin-top: 20px;
    }
  }

  div[data-key^="celsius_balances"],
  div[data-key^="celsius_total_withdrawns"],
  div[data-key^="celsius_total_deposits"] {
    >div {
      border-bottom: 1px solid #EDEDED;
      border-radius: 0 !important;
    }



    .sd-question__header {
      padding-top: 20px !important;
    }

    .sd-question__content {
      .sd-paneldynamic__buttons-container {
        padding-bottom: 30px !important;
      }

      // padding-bottom: 30px !important;
    }

    div[data-key^="Coin"],
    div[data-key^="Quantity"],
    div[data-key^="price"] {
      min-width: 200px !important;
    }

    div[data-key^="Quantity"],
    div[data-key^="price"] {
      padding-left: 20px !important;
    }

    div[data-key^="Claim Value"] {
      .sd-question__header {
        padding-bottom: 8px;
      }
    }

    .sd-paneldynamic__panel-footer {
      margin: 0;
      margin-top: -40px;
      justify-content: flex-end !important;

      button {
        padding: 0;
        background-color: unset !important;
        color: #03ABAB;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  div[data-key^="celsius_relationship"] {
    margin-top: 14px;
    padding-bottom: 30px;
    border-bottom: 1px solid #EDEDED;
  }

  .sd-checkbox {
    .sd-checkbox__decorator {
      width: 20px !important;
      height: 20px !important;
      border: 1px solid #D0D5DD;
      box-shadow: unset;
    }

    .sd-item__control-label {
      font-weight: 500;
      font-size: 16px;
      line-height: unset;
      color: #666666;
    }
  }

  .sd-checkbox--checked {
    .sd-checkbox__decorator {
      background: #E2F2F2;
      border: 1px solid #03ABAB !important;

      svg {
        use {
          fill: #03ABAB;
        }
      }
    }

  }

  .sd-question__header--location-top {
    padding-bottom: 6px !important;

  }

  .sd-element--complex.sd-element--with-frame>.sd-element__header--location-top:after {
    content: none !important;
  }

  .sd-paneldynamic__separator {
    display: none !important;
  }

  .sd-paneldynamic__add-btn {
    background: #E2F2F2;
    border-radius: 5px;
    color: #03ABAB;
    font-weight: 600;
    font-size: 16px;
  }

  .sd-paneldynamic__buttons-container .sd-action-bar {
    margin-left: 0 !important;
  }

  .sd-paneldynamic {
    .sd-paneldynamic__placeholder {
      min-height: unset;
      align-items: start;
      text-align: start;

      .sv-string-viewer {
        white-space: unset;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
    }
  }
}

.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 26px;
  margin-top: 150px;

  h2 {
    margin: 0px;
    text-align: center;
  }
}