.tag {
  height: 28px;
  border: 1px solid #E2E2E2;
  border-radius: 36px;
  padding: 10px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  width: fit-content;
}