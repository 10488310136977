@import 'varible';


body,
#root {
  font-family: 'Inter', sans-serif;

  height: 100%;
} 

.pdf-section {
  p {
    margin: 0px;
  }
}

@mixin make-container($minWidth, $breakpoint) {
  @media (min-width: $minWidth) {
    .container {
      max-width: $breakpoint;
    }
  }
}

@include make-container($screen-xs-min, $screen-xs);
@include make-container($screen-sm-min, $screen-sm);
@include make-container($screen-md-min, $screen-md);
@include make-container($screen-lg-min, $screen-lg);
@include make-container($screen-xl-min, $screen-xl);
@include make-container($screen-xxl-min, $screen-xxl);