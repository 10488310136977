.container {
  display: flex;
  flex-wrap: wrap;

  .information-block {
    flex: 1 1 33%;
    gap: 16px;
    font-size: 14px;
    line-height: 20px;
    margin: 15px 0;

    .information-block__label {
      font-weight: 500;
      color: #666666;
    }

    .information-block__description {
      font-weight: 600;
      color: #111111;
    }
  }
  .information-block-2 {
    flex: 1 1 49%;
    gap: 16px;
    font-size: 14px;
    line-height: 20px;
    margin: 15px 0;
    display: flex;

    .information-block__label {
      font-weight: 500;
      color: #666666;
      margin-top: 6px;
    }

    .information-block__description {
      font-weight: 600;
      color: #111111;
    }
  }
  :global(.ant-select) {
    width: 160px
  }
}
