@import '../../../style/varible';

.wrapper {
  display: flex;
  flex-direction: column;
}

.row {
  height: 60px;
  background: white;
  border: 1px solid #EDEDED;
  box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0;

  >div {
    label {
      display: none;
    }
  }
}

%cell {
  font-size: 14px;
  line-height: 20px;
}

.cell1 {
  width: 30%;
  @extend %cell;
  padding-left: 20px;
  font-weight: 600;
}

.cell2 {
  width: 20%;
  @extend %cell;
  padding-left: 20px;
}

.cell3 {
  width: 20%;
  @extend %cell;
  padding-left: 20px;
}

.cell4 {
  width: 20%;
  @extend %cell;
  padding-left: 20px;
}

.cell5 {
  width: 20%;
  @extend %cell;
  padding-left: 20px;
}

.cell6 {
  width: 10%;
  @extend %cell;
  padding-left: 20px;
}

.cell7 {
  width: 5%;
  @extend %cell;
  display: flex;
  justify-content: center;

  img {
    cursor: pointer;
  }

  button {
    display: none;
  }
}

.claimInfo {
  background: #FBFBFB;
  border-top: 1px solid #EDEDED;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
}

.subTable {
  font-size: 12px;
  line-height: 18px;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;

  .subTableHeader {
    font-weight: 600;
    color: #111111;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #EDEDED;

  }

  .subTableCell {
    padding-top: 16px;
    padding-bottom: 16px;
    color: #666666;
  }
}

.claimAmount {
  background: #FBFBFB;
  border-top: 1px solid #EDEDED;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.amountTable {
  grid-template-columns: auto auto auto auto auto;
}

@media (max-width: $screen-md-max) {
  .row {
    flex-direction: column;
    height: auto;
    padding: 12px;

    >div {
      width: 100%;
      padding-left: 0;
      padding-top: 14px;
      padding-bottom: 14px;
      border-bottom: 1px solid #EDEDED;
      display: flex;
      flex-direction: column;
      gap: 6px;

      >label {
        display: block;
        color: #666666;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        >a {
          text-decoration-line: underline;
          color: #03ABAB;
          font-weight: 400;
        }
      }

      >span {
        display: flex;
        align-items: center;
      }
    }

    // .cell1 {
    //   border-bottom: 1px solid #EDEDED;
    //   padding-bottom: 14px;
    // }

    // .cell2 {
    //   border-bottom: 1px solid #EDEDED;
    //   padding-bottom: 14px;
    // }

    // .cell3 {
    //   border-bottom: 1px solid #EDEDED;
    //   padding-bottom: 14px;
    // }

    // .cell4 {
    //   border-bottom: 1px solid #EDEDED;
    //   padding-bottom: 14px;
    // }

    // .cell5 {
    // border-bottom: none;
    // padding-bottom: 14px;
    // }

    .cell6 {
      border-bottom: none;

      >img {
        display: none;
      }

      >button {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
